require('./bootstrap');
window.$ = window.jQuery = jQuery;

require('corejs-typeahead');
window.Bloodhound = require('corejs-typeahead/dist/bloodhound');

require('./bootstrap-tagsinput');

window.Vapor = require('laravel-vapor');

const algoliasearch = require("algoliasearch");
const client = algoliasearch(process.env.MIX_ALGOLIA_APP_ID, process.env.MIX_ALGOLIA_SECRET);
const index = client.initIndex("documents");

import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

jQueryBridget( 'masonry', Masonry, $ );

var list = $('#list')
var grid = $('#grid')
//var page_view = $('#page-view').val();
var page_view = 'grid';
var search_view = $('#page-search');
var page_view_2 = $('#page-view');
var document_page = $('#document-page');
var sidebar = $('#sidebar');
var sidebar_flag = false;
var main = $('#main');
var search_button = $('#search_button');
var close_button = $('#close_button');
var search_string = "";
var result_array;

var search = $('#search');
var press_search = $('#press_search');
var theme_search = $('#theme_search');
var year_search = $('#year_search');
var type_search = $('#type_search');
var fromwho_search = $('#fromwho_search');
var towhom_search = $('#towhom_search');
var lang_search = $('#lang_search');
var card_tags = (Array)($('.btn.tag'));
var content = $('#content');
var all_document_inner_html;
var global_request = null;
var masonry_instance = null;
var not_found_flag = false;

var page_link = ($('.page-link')).toArray();

class Request {

    search = null
    press  = null
    year  = null
    type  = null
    theme = null
    fromwho = null
    towhom = null
    lang = null
    tag = null

    constructor() {
    }

    setSearch(input) {
        if(input === "") {
            this.search = null
        } else {
            this.search = input
        }
    }

    setPress(input) {
        if(input === "") {
            this.press = null
        } else {
            this.press = input
        }
    }

    setYear(input) {
        if(input === "") {
            this.year = null
        } else {
            this.year = input
        }
    }

    setType(input) {
        if(input === "") {
            this.type = null
        } else {
            this.type = input
        }
    }

    setTheme(input) {
        if(input === "") {
            this.theme = null
        } else {
            this.theme = input
        }
    }

    setFromWho(input) {
        if(input === "") {
            this.fromwho = null
        } else {
            this.fromwho = input
        }
    }

    setToWhom(input) {
        if(input === "") {
            this.towhom = null
        } else {
            this.towhom = input
        }
    }

    setLang(input) {
        if(input === "") {
            this.lang = null
        } else {
            this.lang = input
        }
    }

    setTag(input) {
        if(input === "") {
            this.tag = null
        } else {
            this.tag = input
        }
    }

    getTag() {
        return this.tag;
    }
}

var request = new Request();

$(document).ready(function() {

    if(document.getElementById('response-wrapper'))
        all_document_inner_html = document.getElementById('response-wrapper').innerHTML;

    window.image = getImage;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if ( params.search === 'true') {
        toggle_sidebar();
    }



    if ( params.view === 'list') {
        listClick();
    } else {
        grid.addClass('active');
    }

    list.click(listClick);
    grid.click(gridClick);

    search_button.click(toggle_sidebar);
    close_button.click(toggle_sidebar);

    setSearchChangeHandler();

    setLinks('all');


    switch (page_view) {
        case "list": {
            page_view = "list";

            $('#response-wrapper').removeClass('row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4').addClass('row-cols-sm-1 row-cols-md-2 g-1');
            var card = $('.card')
            card.find('.document-grid-list').disabled = true;
            card.removeClass('document-grid-card').addClass('document-list-card');
            list.addClass('active');
            grid.removeClass('active');
            page_view_2.val("list");

            if(search_view.val() === 'true')
                toggle_sidebar_default();

            break;
        }
        case "grid": {
            page_view = "grid";

            $('#response-wrapper').removeClass('row-cols-sm-1 row-cols-md-2 g-1').addClass('row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4');
            $('.card').removeClass('document-list-card').addClass('document-grid-card');
            grid.addClass('active');
            list.removeClass('active');
            page_view_2.val("grid");

            if(search_view.val() === 'true')
                toggle_sidebar_default();

            break;
        }
        default: {
            page_view = "grid";
            grid.trigger('click');

            if(search_view.val() === 'true')
                toggle_sidebar_default();
        }
    }


    /*
    masonry_instance = $('#document-wrapper').masonry({
        itemSelector: '.col',
        isAnimated: true,
        horizontalOrder: true,
        animationOptions: {
            duration: 700,
            easing: 'linear',
            queue: false
        }
    });


     */

    /*
    masonry_instance.on( 'layoutComplete', function() {
        console.log("layout complete");
    })

     */

    document_page.css('visibility','visible');
});

function listClick() {
    //console.log("listclick");
    /*
    if(search_view.val() === 'true') {
        var url = updateQueryStringParameter(window.location.href, 'search', 'true')
        window.location.href = updateQueryStringParameter(url, 'view', 'list')
    }else {
        var url = updateQueryStringParameter(window.location.href, 'search', 'false')
        window.location.href = updateQueryStringParameter(url, 'view', 'list')
    }*/
    page_view = "list";

    setLinks('view');

    customLink(page_link,sidebar_flag,page_view);

    viewAndRowColsSync();

    var card = $('.card')
    card.find('.document-grid-list').disabled = true;
    card.removeClass('document-grid-card').addClass('document-list-card');

    /*
    masonry_instance = masonry_instance.masonry('destroy');
    masonryInit();

     */

    list.addClass('active');
    grid.removeClass('active');
    page_view_2.val("list");
}

function gridClick() {
    //console.log("gridclick");
    /*
    masonry_instance = masonry_instance.masonry('destroy');

     */
    /*
    if(search_view.val() === 'true') {
        var url = updateQueryStringParameter(window.location.href, 'search', 'true')
        window.location.href = updateQueryStringParameter(url, 'view', 'grid')
    }else {
        var url = updateQueryStringParameter(window.location.href, 'search', 'false')
        window.location.href = updateQueryStringParameter(url, 'view', 'grid')
    }*/
    page_view = "grid";

    setLinks('view');

    viewAndRowColsSync();

    $('.card').removeClass('document-list-card').addClass('document-grid-card');

    /*
    masonryInit();
     */

    grid.addClass('active');
    list.removeClass('active');
    page_view_2.val("grid");
}

function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}

function toggle_sidebar() {
    if(!sidebar_flag)
    {
        sidebar.toggleClass('active');
        sidebar.one('transitionend', function(){
            if (window.screen.width <= 768){
                content.fadeToggle();
            }

            /*
            masonry_instance = masonry_instance.masonry('destroy');

            sidebar.one('transitionend', function() {
                masonryInit();
            })

             */


            setLinks('search');
        })
    }else {
        /*
        masonry_instance = masonry_instance.masonry('destroy');

         */
        sidebar.toggleClass('active');

        sidebar.one('transitionend', function() {

            if (window.screen.width <= 768) {
                content.fadeToggle();
            }

            setLinks('search');

            /*
            sidebar.one('transitionend', function () {

                console.log(masonry_instance);
                masonryInit();
            })

             */
        });
    }
    sidebar_flag = !sidebar_flag;
    /*
    if(search_view.val() === 'true') {

        search_view.val('false')
    }
    else {
        search_view.val('true')
    }
     */
}

function toggle_sidebar_default() {
    sidebar.toggleClass('active');
}

function bodyShow() {
    //callback function code, populating fields
    $('body').show();
}

/*
function searchWithAlgolia(search_string) {

    index
        .search(
            search_string
        )
        .then(({hits}) => {
            //console.log(hits);
            render(hits)
        })
}
 */

function searchWithScout(request) {

    global_request = request;
    not_found_flag = false;

    axios.post('/search', request).then(
        async response => {
            //console.log(response.data);

            let res = await render(response.data);
            //console.log("res");
            if (page_view === 'list') {
                listClick();
            }
        });
}

function viewAndRowColsSync() {
    if(page_view === "grid")
        if(not_found_flag === true) {
            $('#response-wrapper').removeClass("row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4").addClass("row-cols-sm-1 row-cols-md-2 g-1");
        }
        else
            $('#response-wrapper').removeClass("row-cols-sm-1 row-cols-md-2 g-1").addClass("row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4");
    else
        $('#response-wrapper').removeClass("row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4").addClass("row-cols-sm-1 row-cols-md-2 g-1");
}

/*
function changeEventHandler(event) {
    //event.target.value hozzaadasa a search stringhez
    search_string += " " + event.target.value

    console.log(search_string)

    searchWithAlgolia(search_string);
}
 */

function render(response) {

    //console.log(response);

    var response_wp = document.getElementById('response-wrapper');
    var pagination = document.getElementById('pagination');

    response_wp.style.opacity = '0';
    pagination.style.opacity = '0';


    return new Promise((resolve) => {
        setTimeout(function (){
            /*
            masonry_instance.masonry('destroy');

             */
            response_wp.innerHTML = null;
            pagination.style.display = 'none';
        },500);

        setTimeout(function(){

            if(requestIsNull(global_request)) {
                response_wp.innerHTML = all_document_inner_html;
                viewAndRowColsSync();
                /*
                masonryInit();

                 */
                response_wp.style.opacity = '1';
                pagination.style.display = 'block';
                pagination.style.opacity = '1';
                //console.log("render global_request is null");
                return true;
            }
            else {
                if($.isEmptyObject(response)) {
                    not_found_flag = true;
                    response_wp.innerHTML = '' +
                        '<div class="col">' +
                            '<div class="card m-5 document-grid-card">' +
                                '<div class="card-body">' +
                                    '<div class="card-list-link"' +
                                        '<h5 class="card-title">' +
                                            'Nincs a paramétereknek megfelelő találat! Kérlek próbálj más keresési paramétereket!' +
                                        '</h5>' +
                                    '</div>' +
                                    '<h5 class="card-title">' +
                                        'Nincs a paramétereknek megfelelő találat! Kérlek próbálj más keresési paramétereket!' +
                                    '</h5>' +
                                '</div>' +
                            '</div>' +
                        '</div>';

                    viewAndRowColsSync();
                    /*
                    masonryInit();

                     */
                    response_wp.style.opacity = '1';
                    //console.log("render response is empty");
                    return true;
                }
                else {
                    /*
                    response_wp.innerHTML = '' +
                        response.map(function(card) {
                            if(card.cover_image === null) //cover_image nincs
                            {
                        if(card.file === null) // file sincs
                    {
                            if(card.url === null) {
                                return ''+
                                    '<div class="col">' +
                                    '<div class="card document-grid-card">' +
                                    '<img class="card-img-top" src=\"' + getImage( 'public/sample/scouting_for_boys.jpg') + '\"> '+
                                    '<div class="card-body">' +
                                    '<a href=\"/documents/' + card.id + '\" class="card-list-link">' +
                                    '<h5 class="card-title">' +
                                    card.title +
                                    '</h5>' +
                                    '</a>' +
                                    '<h5 class="card-title">' +
                                    card.title +
                                    '</h5>' +
                                    '<h6 class="card-subtitle mb-2">'+
                                    card.authors +
                                    '<br>'+
                                    card.year_of_publication +
                                    '<br>'+
                                    card.type.title +
                                    '<br>'+
                                    card.from_who.title +
                                    '<br>'+
                                    card.to_whom.title +
                                    '<br>'+
                                    '<div class="card-grid-tags">' +
                                    card.tags.map(function(tag) {
                                        return ''+
                                            '<div class="card-tag">' +
                                            tag.title +
                                            '</div>';
                                    }).join('') +
                                    '</div>' +
                                    '<button type="button" data-bs-target=\"#previewModal-'+ card.id +'\" data-bs-toggle="modal" class="card-list-link btn m-0 p-0 mb-2">' +
                                    '[' + "Fájl nincs feltöltve!".toUpperCase() + ']' +
                                    '</button>' +
                                    '<a href=\"/documents/'+ card.id +'\" class="card-grid-link">' +
                                    '</a>' +
                                    '</h6>' +
                                    '</div>' +
                                    '</div>' +
                                    '</div>';
                            }
                            else {
                                return ''+
                                    '<div class="col">' +
                                    '<div class="card document-grid-card">' +
                                    '<img class="card-img-top" src=\"' + getImage( 'public/sample/scouting_for_boys.jpg') + '\"> '+
                                    '<div class="card-body">' +
                                    '<a href=\"/documents/' + card.id + '\" class="card-list-link">' +
                                    '<h5 class="card-title">' +
                                    card.title +
                                    '</h5>' +
                                    '</a>' +
                                    '<h5 class="card-title">' +
                                    card.title +
                                    '</h5>' +
                                    '<h6 class="card-subtitle mb-2">'+
                                    card.authors +
                                    '<br>'+
                                    card.year_of_publication +
                                    '<br>'+
                                    card.type.title +
                                    '<br>'+
                                    card.from_who.title +
                                    '<br>'+
                                    card.to_whom.title +
                                    '<br>'+
                                    '<div class="card-grid-tags">' +
                                    card.tags.map(function(tag) {
                                        return ''+
                                            '<div class="card-tag">' +
                                            tag.title +
                                            '</div>';
                                    }).join('') +
                                    '</div>' +
                                    '<button type="button" data-bs-target=\"#previewModal-'+ card.id +'\" data-bs-toggle="modal" class="card-list-link btn m-0 p-0 mb-2">' +
                                    '[' + card.url.toUpperCase() + ']' +
                                    '</button>' +
                                    '<a href=\"/documents/'+ card.id +'\" class="card-grid-link">' +
                                    '</a>' +
                                    '</h6>' +
                                    '</div>' +
                                    '</div>' +
                                    '<div class="modal fade w-100 h-100" id=\"previewModal-'+ card.id +'\" tabindex="-1" aria-labelledby=\"previewModalLabel-'+ card.id +'\" aria-hidden="true">' +
                                    '<div class="modal-dialog w-100 h-100 m-0" style="max-width: initial!important">' +
                                    '<div class="modal-content w-100 h-100">' +
                                    '<div class="modal-header">' +
                                    '<h5 class="modal-title" id=\"previewModalLabel-'+ card.id +'\">' +
                                    '<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">' +

                                    '</button>' +
                                    '</h5>' +
                                    '</div>' +
                                    '<div class="modal-body">' +
                                    '<iframe class="doc w-100 h-100" src=\"https://docs.google.com/gview?url='+ card.url +'&embedded=true\" >' +
                                    '</iframe>' +
                                    '</div>' +
                                    '<div class="modal-footer">' +
                                    '<button type="button" class="btn btn-danger" data-bs-dismiss="modal">' +
                                    "Bezárás" +
                                    '</button>' +
                                    '</div>' +
                                    '</div>' +
                                    '</div>'+
                                    '</div>'+
                                    '</div>';
                            }

                        }
                        else { //file van
                            return '' +
                                '<div class="col">' +
                                '<div class="card document-grid-card">' +
                                '<img class="card-img-top" src=\"' + getImage( 'public/sample/scouting_for_boys.jpg') + '\"> '+
                                '<div class="card-body">' +
                                '<a href=\"/documents/' + card.id + '\" class="card-list-link">' +
                                '<h5 class="card-title">' +
                                card.title +
                                '</h5>' +
                                '</a>' +
                                '<h5 class="card-title">' +
                                card.title +
                                '</h5>' +
                                '<h6 class="card-subtitle mb-2">'+
                                card.authors +
                                '<br>'+
                                card.year_of_publication +
                                '<br>'+
                                card.type.title +
                                '<br>'+
                                card.from_who.title +
                                '<br>'+
                                card.to_whom.title +
                                '<br>'+
                                '<div class="card-grid-tags">' +
                                card.tags.map(function(tag) {
                                    return ''+
                                        '<div class="card-tag">' +
                                        tag.title +
                                        '</div>';
                                }).join('') +
                                '</div>' +
                                '<button type="button" data-bs-target=\"#previewModal-'+ card.id +'\" data-bs-toggle="modal" class="card-list-link btn m-0 p-0 mb-2">' +
                                '[' + card.file_name.toUpperCase() + ']' +
                                '</button>' +
                                '<a href=\"/documents/'+ card.id +'\" class="card-grid-link">' +
                                '</a>' +
                                '</h6>' +
                                '</div>' +
                                '</div>' +
                                '<div class="modal fade w-100 h-100" id=\"previewModal-'+ card.id +'\" tabindex="-1" aria-labelledby=\"previewModalLabel-'+ card.id +'\" aria-hidden="true">' +
                                '<div class="modal-dialog w-100 h-100 m-0" style="max-width: initial!important">' +
                                '<div class="modal-content w-100 h-100">' +
                                '<div class="modal-header">' +
                                '<h5 class="modal-title" id=\"previewModalLabel-'+ card.id +'\">' +
                                '<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">' +

                                '</button>' +
                                '</h5>' +
                                '</div>' +
                                '<div class="modal-body">' +
                                '<iframe class="doc w-100 h-100" src=\"https://docs.google.com/gview?url='+ card.file +'&embedded=true\" >' +
                                '</iframe>' +
                                '</div>' +
                                '<div class="modal-footer">' +
                                '<button type="button" class="btn btn-danger" data-bs-dismiss="modal">' +
                                "Bezárás" +
                                '</button>' +
                                '</div>' +
                                '</div>' +
                                '</div>'+
                                '</div>'+
                                '</div>';
                        }
                    }else //cover image van
                    {

                        if(card.file === null) // file nincs
                        {
                            if(card.url === null) {
                                return ''+
                                    '<div class="col">' +
                                    '<div class="card document-grid-card">' +
                                    '<img class="card-img-top" src=\"' + getImage( 'public/sample/scouting_for_boys.jpg') + '\"> '+
                                    '<div class="card-body">' +
                                    '<a href=\"/documents/' + card.id + '\" class="card-list-link">' +
                                    '<h5 class="card-title">' +
                                    card.title +
                                    '</h5>' +
                                    '</a>' +
                                    '<h5 class="card-title">' +
                                    card.title +
                                    '</h5>' +
                                    '<h6 class="card-subtitle mb-2">'+
                                    card.authors +
                                    '<br>'+
                                    card.year_of_publication +
                                    '<br>'+
                                    card.type.title +
                                    '<br>'+
                                    card.from_who.title +
                                    '<br>'+
                                    card.to_whom.title +
                                    '<br>'+
                                    '<div class="card-grid-tags">' +
                                    card.tags.map(function(tag) {
                                        return ''+
                                            '<div class="card-tag">' +
                                            tag.title +
                                            '</div>';
                                    }).join('') +
                                    '</div>' +
                                    '<button type="button" data-bs-target=\"#previewModal-'+ card.id +'\" data-bs-toggle="modal" class="card-list-link btn m-0 p-0 mb-2">' +
                                    '[' + "Fájl nincs feltöltve!".toUpperCase() + ']' +
                                    '</button>' +
                                    '<a href=\"/documents/'+ card.id +'\" class="card-grid-link">' +
                                    '</a>' +
                                    '</h6>' +
                                    '</div>' +
                                    '</div>' +
                                    '</div>';
                            }
                            else {
                                return ''+
                                    '<div class="col">' +
                                    '<div class="card document-grid-card">' +
                                    '<img class="card-img-top" src=\"' + card.cover_image + '\"> '+
                                    '<div class="card-body">' +
                                    '<a href=\"/documents/' + card.id + '\" class="card-list-link">' +
                                    '<h5 class="card-title">' +
                                    card.title +
                                    '</h5>' +
                                    '</a>' +
                                    '<h5 class="card-title">' +
                                    card.title +
                                    '</h5>' +
                                    '<h6 class="card-subtitle mb-2">'+
                                    card.authors +
                                    '<br>'+
                                    card.year_of_publication +
                                    '<br>'+
                                    card.type.title +
                                    '<br>'+
                                    card.from_who.title +
                                    '<br>'+
                                    card.to_whom.title +
                                    '<br>'+
                                    '<div class="card-grid-tags">' +
                                    card.tags.map(function(tag) {
                                        return ''+
                                            '<div class="card-tag">' +
                                            tag.title +
                                            '</div>';
                                    }).join('') +
                                    '</div>' +
                                    '<button type="button" data-bs-target=\"#previewModal-'+ card.id +'\" data-bs-toggle="modal" class="card-list-link btn m-0 p-0 mb-2">' +
                                    '[' + card.url.toUpperCase() + ']' +
                                    '</button>' +
                                    '<a href=\"/documents/'+ card.id +'\" class="card-grid-link">' +
                                    '</a>' +
                                    '</h6>' +
                                    '</div>' +
                                    '</div>' +
                                    '<div class="modal fade w-100 h-100" id=\"previewModal-'+ card.id +'\" tabindex="-1" aria-labelledby=\"previewModalLabel-'+ card.id +'\" aria-hidden="true">' +
                                    '<div class="modal-dialog w-100 h-100 m-0" style="max-width: initial!important">' +
                                    '<div class="modal-content w-100 h-100">' +
                                    '<div class="modal-header">' +
                                    '<h5 class="modal-title" id=\"previewModalLabel-'+ card.id +'\">' +
                                    '<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">' +

                                    '</button>' +
                                    '</h5>' +
                                    '</div>' +
                                    '<div class="modal-body">' +
                                    '<iframe class="doc w-100 h-100" src=\"https://docs.google.com/gview?url='+ card.url +'&embedded=true\" >' +
                                    '</iframe>' +
                                    '</div>' +
                                    '<div class="modal-footer">' +
                                    '<button type="button" class="btn btn-danger" data-bs-dismiss="modal">' +
                                    "Bezárás" +
                                    '</button>' +
                                    '</div>' +
                                    '</div>' +
                                    '</div>'+
                                    '</div>'+
                                    '</div>';
                            }
                        }
                        else {
                            return ''+
                                '<div class="col">' +
                                '<div class="card document-grid-card">' +
                                '<img class="card-img-top" src=\"' + card.cover_image + '\"> '+
                                '<div class="card-body">' +
                                '<a href=\"/documents/' + card.id + '\" class="card-list-link">' +
                                '<h5 class="card-title">' +
                                card.title +
                                '</h5>' +
                                '</a>' +
                                '<h5 class="card-title">' +
                                card.title +
                                '</h5>' +
                                '<h6 class="card-subtitle mb-2">'+
                                card.authors +
                                '<br>'+
                                card.year_of_publication +
                                '<br>'+
                                card.type.title +
                                '<br>'+
                                card.from_who.title +
                                '<br>'+
                                card.to_whom.title +
                                '<br>'+
                                '<div class="card-grid-tags">' +
                                card.tags.map(function(tag) {
                                    return ''+
                                        '<div class="card-tag">' +
                                        tag.title +
                                        '</div>';
                                }).join('') +
                                '</div>' +
                                '<button type="button" data-bs-target=\"#previewModal-'+ card.id +'\" data-bs-toggle="modal" class="card-list-link btn m-0 p-0 mb-2">' +
                                '[' + card.file_name.toUpperCase() + ']' +
                                '</button>' +
                                '<a href=\"/documents/'+ card.id +'\" class="card-grid-link">' +
                                '</a>' +
                                '</h6>' +
                                '</div>' +
                                '</div>' +
                                '<div class="modal fade w-100 h-100" id=\"previewModal-'+ card.id +'\" tabindex="-1" aria-labelledby=\"previewModalLabel-'+ card.id +'\" aria-hidden="true">' +
                                '<div class="modal-dialog w-100 h-100 m-0" style="max-width: initial!important">' +
                                '<div class="modal-content w-100 h-100">' +
                                '<div class="modal-header">' +
                                '<h5 class="modal-title" id=\"previewModalLabel-'+ card.id +'\">' +
                                '<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">' +

                                '</button>' +
                                '</h5>' +
                                '</div>' +
                                '<div class="modal-body">' +
                                '<iframe class="doc w-100 h-100" src=\"https://docs.google.com/gview?url='+ card.file +'&embedded=true\" >' +
                                '</iframe>' +
                                '</div>' +
                                '<div class="modal-footer">' +
                                '<button type="button" class="btn btn-danger" data-bs-dismiss="modal">' +
                                'Bezárás' +
                                '</button>' +
                                '</div>' +
                                '</div>' +
                                '</div>'+
                                '</div>'+
                                '</div>'+
                                '';
                        }
                    }
                }).join('');
                     */

                    //var resp = JSON.parse(response);
                    response.forEach(document => {
                        response_wp.innerHTML += document;
                    })
                    viewAndRowColsSync();
                    /*
                    masonryInit();

                     */
                    response_wp.style.opacity = '1';
                    //console.log("render response content")
                    return true;
                }
            }
        },500);

        //console.log("render ends");
        setTimeout(resolve, 500);
    })
}

function setSearchChangeHandler() {

    search.change(event => {
        request.setSearch(event.target.value)
        searchWithScout(request)
    });

    press_search.change(event => {
        request.setPress(event.target.value);
        searchWithScout(request)
    })

    theme_search.change(event => {
        request.setTheme(event.target.value);
        searchWithScout(request)
    });

    year_search.change(event => {
        request.setYear(event.target.value);
        searchWithScout(request)
    });

    type_search.change(event => {
        request.setType(event.target.value);
        searchWithScout(request)
    });

    fromwho_search.change(event => {
        request.setFromWho(event.target.value);
        searchWithScout(request)
    });

    towhom_search.change(event => {
        request.setToWhom(event.target.value);
        searchWithScout(request)
    });

    lang_search.change(event => {
        request.setLang(event.target.value);
        searchWithScout(request)
    });

    card_tags.forEach( tag => {
        tag.click(function() {
            card_tags.forEach( tag => {
                tag.removeClass('active')
            });

            //console.log($(this).text())
            if(request.getTag() !== null){
                if(request.getTag() === $(this).text().split("(")[0].slice(0, -1)){
                    request.setTag(null)
                }else
                {
                    request.setTag($(this).text().split("(")[0].slice(0, -1));
                    $(this).addClass('active');
                }
            }else {
                    request.setTag($(this).text().split("(")[0].slice(0, -1))
                    //console.log(request.getTag())
                    $(this).addClass('active');
            }
            searchWithScout(request)
        })
    })
}

function setInnerHTML(elm, html) {
    elm.innerHTML = html;
    Array.from(elm.querySelectorAll("script")).forEach( oldScript => {
        const newScript = document.createElement("script");
        Array.from(oldScript.attributes)
            .forEach( attr => newScript.setAttribute(attr.name, attr.value) );
        newScript.appendChild(document.createTextNode(oldScript.innerHTML));
        oldScript.parentNode.replaceChild(newScript, oldScript);
    });
}

function getImage(asset_path){
    return  (window.Vapor.asset(asset_path));
}

function requestIsNull(request){

    return Object.values(request).every(value => {
        if (value === null) {

            return true;
        }

        return false;
    });
}

function setLinks(param) {
    if(page_link !== null) {
        page_link.forEach( link => {
            if( link.href !== undefined) {
                link.href = customLink(link.href,sidebar_flag, page_view, param)
            }
        })
    }
}

function customLink(link, search, view, param) {

    if(param === 'view' || param === 'all'){
        if(link.includes('list') || link.includes('grid'))
            link = link.slice(0,link.indexOf('view')+5) + link.slice(link.indexOf('view')+9);

        var view_last_index = link.indexOf('view')+5;
        link = link.slice(0, view_last_index ) + view + link.slice(view_last_index);
    }

    if(param === 'search' || param === 'all') {
        if(link.includes('true'))
            link = link.slice(0,link.indexOf('search')+7) + link.slice(link.indexOf('search')+11);

        if(link.includes('false'))
            link = link.slice(0,link.indexOf('search')+7) + link.slice(link.indexOf('search')+12);

        var search_last_index = link.indexOf('search')+7;
        link = link.slice(0, search_last_index ) + search + link.slice(search_last_index);
    }

    return link;
}

function masonryInit() {
    masonry_instance.imagesLoaded(function() {
        console.log('imagesLoaded');
        masonry_instance = masonry_instance.masonry({
            itemSelector: '.col',
            isAnimated: true,
            horizontalOrder: true,
            animationOptions: {
                duration: 700,
                easing: 'linear',
                queue: false
            }
        });
    });
}
